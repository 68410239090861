import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { BgImage } from 'gbimage-bridge'
import { getImage } from 'gatsby-plugin-image'
import { useStaticQuery, graphql } from 'gatsby'

import Wave from '../../../assets/wave.inline.svg'
import media from '../../../utils/media'

const Container = styled.div`
  position: relative;
  width: 100%;
`

const StyledWave = styled(Wave)`
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 100%;
  height: auto;

  ${media.greaterThan('xl')`
    width: 70%;
  `}
`

const Content = styled.div`
  width: 100%;
  margin: auto;
  min-height: 650px;
  padding-top: 180px;

  ${media.lessThan('sm')`
    min-height: 580px;
    padding-top: 120px;
  `}
  
`

const NewTopSection = ({ className, children }) => {
  const { placeholderImage } = useStaticQuery(graphql`{
    placeholderImage: file(relativePath: {eq: "home/background.webp"}) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
        )
      }
    }
  }`)

  const image = getImage(placeholderImage)
  const images = [
    image,
    'linear-gradient(130deg, #0049EE, #2664F1)'
  ]

  return (
    <Container>
      <BgImage
        Tag='div'
        image={images}
        className={className}
        preserveStackingContext >
        <Content>
          {children}
        </Content>
        <StyledWave />
      </BgImage>
    </Container>
  )
}

NewTopSection.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  component: PropTypes.any,
  absoluteImage: PropTypes.bool
}

NewTopSection.defaultProps = {
  absoluteImage: true
}

const StyledNewTopSection = styled(NewTopSection)`
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`

export default StyledNewTopSection
