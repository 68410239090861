import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import ImageSection from '../../molecules/ImageSection'
import LaunchButton from '../../atoms/LaunchButton'
import ButtonContainer from '../../atoms/ButtonContainer'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const size = {
  lg: {
    height: '503'
  }
}

const HomeMultipleBuyers = () => {
  const { placeholderImage } = useStaticQuery(graphql`{
    placeholderImage: file(relativePath: {eq: "home/multi.webp"}) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
        )
      }
    }
  }`)

  return (
    <ImageSection
      alt='Acheter des locaux seul ou à plusieurs'
      size={size}
      fixed={false}
      right={false}
      imageData={placeholderImage}
      maxText={580}>
      <HomeSectionTitle tag='h2'>
        Acheter des locaux seul ou à plusieurs
      </HomeSectionTitle>
      <HomeSectionSubtitle>
        Est-ce que je dois acheter seul ou avec d’autres professionnels ? Quelle structure est-ce que je dois créer pour cet achat ? Est-ce rentable de sous-louer des locaux à d’autres professionnels?
      </HomeSectionSubtitle>
      <HomeSectionDescription>
        Ces questions nous sont posés tous les jours par les professions libérales et elles sont extrêmement légitimes. C’est pour cela que chez Trouver Mon Local Pro nous faisons un accompagnement personnalisé pour chacun de nos clients et en particulier sur le financement du projet d’achat. Nous établirons une analyse précise de vos capacités et vous choisirez la solution qui conviendra le mieux à vos souhaits.
      </HomeSectionDescription>
      <ButtonContainer>
        <LaunchButton
          link='/nos-annonces/demande-accompagnement'
          title="Lancer une analyse financière de mon projet"
          background='brightOrange' />
      </ButtonContainer>
    </ImageSection>
  )
}

export default HomeMultipleBuyers
