import React from 'react'
import styled from 'styled-components'
import { map } from 'lodash'
import { TextLoop } from 'react-text-loop-next'

import media from '../../../utils/media'
import Header from '../HomeSearchHeader'
import { jobs } from '../../../config/jobs'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const Label = styled.span`
  text-align: center;
`
const StyledTextLoop = styled(TextLoop)`
  text-align: center;
`

const TextContainer = styled.div`
  width: 800px;

  ${media.lessThan('lg')`
    width: 500px;
  `}

  ${media.lessThan('sm')`
    width: 250px;
  `}

`

const HomeJobTitle = () => {
  return (
    <Container>
      <Header center={true}>
        Trouvez un local professionnel pour
      </Header>
      <Header center={true}>
        <StyledTextLoop
          mask={true}>
          {map(jobs, job => {
            return (
              <TextContainer key={job}>
                <Label key={job}>{job}</Label>
              </TextContainer>
            )
          })}
        </StyledTextLoop >{' '}
      </Header>
    </Container >
  )
}

export default HomeJobTitle
