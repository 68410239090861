import React from 'react'
import styled from 'styled-components'

import media from '../../../utils/media'
import LaunchButton from '../../atoms/LaunchButton'
import ButtonContainer from '../../atoms/ButtonContainer'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 830px;
  margin: 0 auto;
  padding: 0 24px ;

  ${media.greaterThan('sm')`
    padding: 0 40px;
  `}

  ${media.greaterThan('md')`
    padding: 0 72px ;
  `}

  ${media.greaterThan('lg')`
    padding: 0;
  `}
`

const HomeLocation = () => (
  <Container>
    <HomeSectionTitle tag='h2'>
      Louer un local professionnel
    </HomeSectionTitle>
    <HomeSectionSubtitle>
      Si vous ne souhaitez pas encore vous installer à long terme dans une ville ou si vous souhaitez occuper un local à temps partiel, la location est sûrement la meilleure solution.
    </HomeSectionSubtitle>
    <HomeSectionDescription>
      Vous pourrez trouver sur notre plateforme, tous les cabinets ou bureaux disponibles à la location pour des professions libérales. En nous donnant plus d’informations sur votre projet, nous pourrons vous guider vers les plus adaptés.    </HomeSectionDescription>
    <ButtonContainer>
      <LaunchButton
        link='/nos-annonces/louer/?action=louer'
        title="Je souhaite louer"
        background='brightOrange' />
    </ButtonContainer>
  </Container>
)

export default HomeLocation
