import React from 'react'
import styled from 'styled-components'

import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import PresentationBlocs from '../PresentationBlocs'

const blocs = [{
  html: 'Identifier les locaux professionnels disponibles les plus adaptés à votre recherche sur le marché',
  link: '/nos-annonces',
  title: 'Acheter ou louer',
  subtitle: 'un local',
  image: 'presentation-1',
  button: 'Voir les locaux'
}, {
  html: 'Créez une alerte pour recevoir en priorité les annonces de locaux qui correspondent à vos critères de recherche',
  link: '/nos-annonces/demande-accompagnement',
  title: 'Être alerté',
  subtitle: 'en priorité',
  image: 'presentation-2',
  button: 'Être alerté en priorité'
}, {
  html: 'Trouvez un acquéreur ou votre futur locataire en déposant votre annonce en 2 minutes',
  link: '/nos-annonces/tarifs',
  title: 'Vendre ou louer',
  subtitle: 'mon local',
  image: 'presentation-3',
  button: 'Déposer une annonce'
}]

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 32px;
`

const CenterTitle = styled(HomeSectionTitle)`
  text-align: center;
`

const HomePresentationService = () => {
  return (
    <Container>
      <CenterTitle tag='h2'>
        Trouver Mon Local Pro vous accompagne dans vos recherches
      </CenterTitle>
      <PresentationBlocs blocs={blocs} />
    </Container>
  )
}

export default HomePresentationService
