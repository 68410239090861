export const jobs = [
  'Kinésithérapeute',
  'Ostéopathe',
  'Infirmier',
  'Psychologue',
  'Psychothérapeute',
  'Sophrologue',
  'Nutritionniste',
  'Psychomotricien',
  'Chiropracteur',
  'Dentiste',
  'Orthopédiste',
  'Prothésiste Dentaire',
  'Orthophoniste',
  'Sage-femme',
  'Gynecologue',
  'Gastro Enterologue',
  'Allergologue',
  'Neurologue',
  'Ophtalmologiste',
  'Stomatologue',
  'Chirurgien',
  'Cancérologue',
  'Pneumologue',
  'Cardiologue',
  'Orthodontiste',
  'Pédiatre',
  'Orthoptiste',
  'Ergotherapeute',
  'Médecin Généraliste',
  'ORL',
  'Architecte',
  'Avocat',
  'Géomètre expert',
  'Vétérinaire',
  'Commissaire priseur',
  'Greffier auprès des tribunaux de commerce',
  'Huissier de justice',
  'Notaire',
  'Commissaire aux comptes',
  'Mandataire judiciaire',
  'Administrateur judiciaire',
  'Agent général d\'assurance',
  'Architecte d\'intérieur',
  'Chiropracteur',
  'Conseil en investissements financiers',
  'Conseil en propriété industrielle',
  'Expert agricole',
  'Foncier',
  'Expert forestier',
  'Expert devant les tribunaux',
  'Expert comptable',
  'Economiste de la construction',
  'Ingénieur conseil',
  'Maître d\'oeuvre',
  'Archéologue',
  'Art thérapeute',
  'Audit et conseil',
  'Coach sportif',
  'Conférencier',
  'Consultant',
  'Détective',
  'Designer',
  'Expert en assurances',
  'Musicothérapeute',
  'Naturopathe',
  'Traducteur',
  'Urbaniste']
