import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'

import {
  get, map, isEqual, capitalize
} from 'lodash'

import media from '../../../utils/media'
import SearchCity from '../../molecules/SearchCity'
import HomeJobTitle from '../../atoms/HomeJobTitle'
import HomeSearchTopSection from '../../molecules/HomeSearchTopSection'

const TrustPilotWidgetHeader = styled.div`
  margin-top: 24px;

  & > a {
    text-decoration: none;
  }
`

const ContentWidget = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${media.lessThan('sm')`
    font-size: 14px;
  `}

  ${media.lessThan('md')`
    justify-content: center;
  `}
`

const ImageContainerStars = styled.div`
  width: 150px;
  height: auto;
  margin-right: 16px;
`

const ImageContainerLogo = styled.div`
  width: 100px;
  height: auto;
`

const TextRatings = styled.p`
  margin: 0 16px 0 8px;
  text-shadow: 0 0 2px #002474;
  font-family: 'Source Sans Pro';
  font-size: 18px;
  font-weight: bold;
  color: ${({ theme }) => get(theme, 'white', '#FFF')};


  & > span {
    font-weight: normal;
  }
`
const Container = styled.div`
  width: 100%;

  ${media.lessThan('md')`
    width: 90%;
    margin-right: auto;
    margin-left: auto;
  `}
`
const Tabs = styled.div`
  display: flex;
  gap: 4px;
  padding: 4px;
  margin: 54px auto 16px auto;
  width: fit-content;
  background-color: white;
  border: 1px solid white;
  border-radius: 20px;
  box-shadow: 0 3px 6px 0 rgba(12, 62, 208, 0.45);
`
const Tab = styled.div`
  padding: 6px 24px;
  border: 1px solid white;
  border-radius: 20px;
  cursor: pointer;
  color: ${({ theme, selected }) => get(theme, selected ? 'white' : 'blue', '#fff')};
  background-color: ${({ theme, selected }) => get(theme, selected ? 'blue' : 'white', '#fff')};
`

const HomeSearch = () => {
  const [selected, updateSelected] = useState('louer')

  const handleSelectClick = useCallback((key) => {
    updateSelected(key)
  }, [updateSelected])

  return (
    <HomeSearchTopSection>
      <Container>
       <HomeJobTitle />
        <Tabs>
          {map(['acheter', 'vendre', 'louer'], key => {
            return (
              <Tab
                key={key}
                selected={isEqual(selected, key)}
                onClick={() => handleSelectClick(key)}>
                {capitalize(key)}
              </Tab>
            )
          })}
        </Tabs>
        <SearchCity action={selected} />
        <TrustPilotWidgetHeader>
          <a href='https://fr.trustpilot.com/review/folhomee.fr' target='_blank' rel="noreferrer">
            <ContentWidget>
              <TextRatings> 4.8 <span>étoiles</span></TextRatings>
              <ImageContainerStars>
                <StaticImage src='../../../images/trustpilot/trustpilot-ratings-5star.png' alt='4.8 étoiles sur 5' />
              </ImageContainerStars>
              <ImageContainerLogo>
                <StaticImage src='../../../images/trustpilot/trustpilot-brandmark.png' alt='TrustPilot' />
              </ImageContainerLogo>
            </ContentWidget>
          </a>
        </TrustPilotWidgetHeader>
      </Container>
    </HomeSearchTopSection>
  )
}

export default HomeSearch
