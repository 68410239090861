import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import LaunchButton from '../../atoms/LaunchButton'
import ImageSection from '../../molecules/ImageSection'
import ButtonContainer from '../../atoms/ButtonContainer'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const size = {
  lg: {
    height: '485'
  },
  xl: {
    height: '405'
  }
}

const HomeMission = () => {
  const { placeholderImage } = useStaticQuery(graphql`{
    placeholderImage: file(relativePath: {eq: "home/mission.webp"}) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
        )
      }
    }
  }`)

  return (
    <ImageSection
      alt='Un réseau de conseillers partout en France pour vous trouver le bien idéal'
      size={size}
      fixed={false}
      right={true}
      maxText={580}
      imageData={placeholderImage}>
      <HomeSectionTitle tag='h2'>
        Notre Mission ? Aider les professions libérales à trouver le local idéal
      </HomeSectionTitle>
      <HomeSectionSubtitle>
        Vous êtes un professionnel en libéral et vous souhaitez acheter ou louer un local pour une 1ère installation ou pour vous agrandir ?      </HomeSectionSubtitle>
      <HomeSectionDescription>
        Lancez dès maintenant votre recherche parmi l’ensemble des locaux que nous proposons.<br/>Nos équipes seront disponibles pour vous accompagner dans toutes vos démarches afin que vous puissiez trouver sereinement le local qui vous correspond.
      </HomeSectionDescription>
      <ButtonContainer>
        <LaunchButton
          link='/nos-annonces/'
          title='Lancer votre recherche'
          background='brightOrange' />
      </ButtonContainer>
    </ImageSection>
  )
}

export default HomeMission
