import React from 'react'
import styled from 'styled-components'
import { get } from 'lodash'

import media from '../../../utils/media'
import ExpertIcon from '../../../assets/expert.inline.svg'
import LaunchButton from '../../atoms/LaunchButton'
import PictoNewBuilding from '../../../assets/new.inline.svg'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import PictoRevenuFiscal from '../../../assets/tax-income.inline.svg'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 770px;
  margin: auto;
  padding: 0 40px;

  ${media.greaterThan('md')`
    padding: 0;
  `}
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const LineContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 32px;
  margin: 32px 0;

  ${media.greaterThan('sm')`
    margin: 40px 0;
    gap: 40px;
  `}
`

const LineContent = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  
  ${media.lessThan('md')`
      flex-direction: column;
      display: flex;
      gap: 16px;
  `}

  & > svg {
    height: 60px;

    ${media.greaterThan('sm')`
      height: 80px;
      width: 80px;
    `}
  }
`

const LineText = styled.div`
  flex-direction: column;
  width: 80%;

  ${media.lessThan('md')`
    width: 100%;
  `}
`

const LineTitle = styled.p`
  font-family: 'Source Sans Pro';
  font-size: 18px;
  font-weight: bold;
  line-height: normal;
  color: ${({ theme }) => get(theme, 'blue')};
  width: 100%;
  margin-bottom: 8px;

  ${media.greaterThan('sm')`
    font-size: 22px;
  `}
`
const LineDescription = styled.p`
  font-family: 'Source Sans Pro';
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  color: ${({ theme }) => get(theme, 'blue')};
  width: 100%;

  ${media.greaterThan('sm')`
    font-size: 16px;
  `}
`
const ActionButton = styled(LaunchButton)`
  width: fit-content;
  align-selft: center;
`

const HomeReasons = () => {
  return (
    <Container>
      <Content>
        <HomeSectionTitle tag='h2'>
          Pourquoi lancer votre recherche avec Trouver Mon Local Pro ?
        </HomeSectionTitle>
        <LineContainer>
          <LineContent>
            <PictoNewBuilding />
            <LineText>
              <LineTitle>
                Une Plateforme de recherche spécialisée simple et efficace
              </LineTitle>
              <LineDescription>
                La vocation première de Trouver un Local Pro est de vous aider dans la recherche d’un local professionnel quel qu’il soit. Sur notre plateforme vous trouverez donc tous les locaux à vendre ou à louer grâce à une recherche rapide et par critères (localisation, surface, profession, budget, etc.)            </LineDescription>
            </LineText>
          </LineContent>
          <LineContent>
            <ExpertIcon />
            <LineText>
              <LineTitle>
                Un accompagnement sur-mesure
              </LineTitle>
              <LineDescription>
                Nous vous accompagnons durant tout votre projet d’installation, de la recherche, en passant par les démarches financières, administratives jusqu’à l’installation. Notre équipe 100% dédiée aux professionnels pourra vous guider pendant chaque étape de ce moment très important dans votre carrière.
              </LineDescription>
            </LineText>
          </LineContent>
          <LineContent>
            <PictoRevenuFiscal />
            <LineText>
              <LineTitle>
                Des experts de l’aménagement et du financement
              </LineTitle>
              <LineDescription>
                Un local professionnel adapté à vos besoins est essentiel pour la réussite de votre activité. Cabinet, bureau, salle d’attente, pièce de repos, l’objectif est d’identifier l’aménagement qui correspondra à vos souhaits. De la même façon, nos experts financiers vous accompagneront pour le financement de votre projet tout en vous conseillant sur le statut le plus adapté à votre activité.            </LineDescription>
            </LineText>
          </LineContent>
          <ActionButton
            link='/acheter-local-professionnel/comment-et-pourquoi-se-lancer'
            title="Comment et pourquoi se lancer"
            background='brightOrange' />
        </LineContainer>
      </Content>
    </Container >
  )
}

export default HomeReasons
