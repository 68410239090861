import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { LocalBusinessJsonLd } from 'gatsby-plugin-next-seo'
import { useStaticQuery, graphql } from 'gatsby'

import SEO from '../components/atoms/Seo'
import Footer from '../components/organisms/Footer'
import Navbar from '../components/organisms/Navbar'
import HomeAds from '../components/organisms/HomeAds'
import HomeSearch from '../components/organisms/HomeSearch'
import SiteSection from '../components/atoms/SiteSection'
import HomeReasons from '../components/organisms/HomeReasons'
// import HomeReviews from '../components/organisms/HomeReviews'
import HomeMission from '../components/organisms/HomeMission'
import HomeLocation from '../components/organisms/HomeLocation'
import HomeMultipleBuyers from '../components/organisms/HomeMultipleBuyers'
import HomePresentationTlmp from '../components/organisms/HomePresentationTlmp'
import HomePresentationService from '../components/organisms/HomePresentationService'

const TITLE = 'Trouver Mon Local Pro'
const DESCRIPTION = 'Trouver votre futur local professionel'

const Home = ({ location }) => {
  const { site, whiteLogo } = useStaticQuery(graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
            phone
            streetAddress
            addressLocality
            postalCode
            addressCountry
            latitude
            longitude
          }
        }
        whiteLogo: file(relativePath: {eq: "logos/logo-blue-medium.png"}) {
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP, AVIF]
              layout: FULL_WIDTH
            )
          }
        }
      }
    `)

  const siteUrl = get(site, 'siteMetadata.siteUrl')
  const imageUrl = get(whiteLogo, 'childImageSharp.gatsbyImageData.images.fallback.src')

  return (
    <>
      <SEO
        title={TITLE}
        description={DESCRIPTION}>
        <LocalBusinessJsonLd
          id={siteUrl}
          url={siteUrl}
          type='RealEstateAgent'
          name={get(site, 'siteMetadata.author')}
          description={DESCRIPTION}
          telephone={get(site, 'siteMetadata.phone')}
          address={{
            streetAddress: get(site, 'siteMetadata.streetAddress'),
            addressLocality: get(site, 'siteMetadata.addressLocality'),
            postalCode: get(site, 'siteMetadata.postalCode'),
            addressCountry: get(site, 'siteMetadata.postalCode')
          }}
          geo={{
            latitude: get(site, 'siteMetadata.latitude'),
            longitude: get(site, 'siteMetadata.longitude')
          }}
          images={[`${siteUrl}${imageUrl}`]} />
      </SEO>
      <Navbar location={location} />
      <HomeSearch />
      <SiteSection id='services-presentation' margin='100px 0 0 0'>
        <HomePresentationService />
      </SiteSection>
      <SiteSection id='tmlp-presentation' margin='100px 0 0 0'>
        <HomePresentationTlmp />
      </SiteSection>
      <SiteSection id='dernieres-nouveautes' margin='120px 0 0 0'>
        <HomeAds type='ad' title='Découvrez nos locaux dans toute la France' />
      </SiteSection>
      <SiteSection id='notre-mission' margin='120px 0 0 0'>
        <HomeMission />
      </SiteSection>
      <SiteSection id='pourquoi-utiliser-tmlp' margin='110px 0 0 0'>
        <HomeReasons />
      </SiteSection>
      <SiteSection id='multiples-acheteurs' margin='50px 0 0 0'>
        <HomeMultipleBuyers />
      </SiteSection>
      <SiteSection id='location' margin='80px 0 80px 0'>
        <HomeLocation />
      </SiteSection>
      <Footer />
    </>
  )
}

Home.propTypes = {
  location: PropTypes.object.isRequired
}

export default Home
