import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import ImageSection from '../../molecules/ImageSection'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const transformScale = {
  xs: {
    transform: 'scale(1.6)'
  },
  md: {
    transform: 'scale(1.2)'
  },
  lg: {
    transform: 'scale(1.2)'
  },
  xl: {
    transform: 'scale(1.25)'
  }
}

const HomePresentationTlmp = () => {
  const { placeholderImage } = useStaticQuery(graphql`{
    placeholderImage: file(relativePath: {eq: "home/tmlp-presentation.webp"}) {
    childImageSharp {
      gatsbyImageData(
        placeholder: BLURRED
      )
    }
  }
}`)

  return (
    <ImageSection
      fixed={false}
      right={false}
      maxText={580}
      imageData={placeholderImage}
      backgroundColor='white'
      $transformScale={transformScale} >
      <HomeSectionTitle tag='h2'>
        Trouver Mon Local Pro, la référence du local professionnel
      </HomeSectionTitle>
      <HomeSectionSubtitle>
        Chez Trouver Mon Local Pro, vous pourrez retrouver tous les locaux professionnels susceptibles de vous intéresser sur une seule et même plateforme.
      </HomeSectionSubtitle>
      <HomeSectionDescription>
      Alliant expertise digitale et accompagnement humain, nous sommes à 100% concentrés pour la réussite de votre projet immobilier.<br />
        L’installation pour un professionnel libéral est une étape importante dans une carrière et peut être une source de stress.<br />Que vous souhaitiez acheter, vendre ou louer un local professionnel, il est essentiel de s’adresser à un acteur qui comprend les enjeux des professions libérales.
      </HomeSectionDescription>
    </ImageSection>
  )
}

export default HomePresentationTlmp
